import { Role } from "@/acl/roles";
import {
  EVENT_COVID19_CRISIS,
  EVENT_DECEMBER_2018_CRISIS,
  EVENT_EURO_CRISIS,
  EVENT_FINANCIAL_CRISIS,
} from "@/assets/constants/eventsDates";
import exports from "@/assets/scss/exports.module.scss";

// Name used for the exposure as a property of all objects using it.
export const EXPOSURE_PROPERTY_NAME = "exposure_value";

// Name used for the risk level as property of all objects using it.
export const RISK_LEVEL_PROPERTY_NAME = "risk_level";

// Date at which most of our indicators were live.
export const INDICATORS_INCEPTION_DATE = new Date("2018-10-22");

// Thresholds for what is considered a good/bad (low/high) value in an indicator.
export const INDICATOR_THRESHOLD_HIGH = 0.75;
export const INDICATOR_THRESHOLD_MODERATE = 0.5;
export const INDICATOR_THRESHOLD_LOW = 0.25;

// Thresholds for what is considered a good/bad (low/high) value as an exposure.
export const EXPOSURE_THRESHOLD_HIGH = 0.75;
export const EXPOSURE_THRESHOLD_LOW = 0.5;

export const defaultGetTooltipTextColorFromValue = (value) => {
  if (value == null) return exports["colors-grey"];
  if (value < INDICATOR_THRESHOLD_LOW) return exports["colors-success"];
  if (value < INDICATOR_THRESHOLD_HIGH) return exports["colors-warning"];
  return exports["colors-danger"];
};

const MAX_OPACITY = 255;
const MAX_WANTED_OPACITY_PERCENTAGE = 0.6;
const valueToOpacity = (value) => {
  return `${exports["colors-danger"]}${parseInt(MAX_OPACITY * value)
    .toString(16)
    .padStart(2, 0)}`;
};

export const defaultGetRadialColorFromValue = (value) => {
  if (value == null) return exports["colors-invisible"];
  return valueToOpacity(value * MAX_WANTED_OPACITY_PERCENTAGE);
};

export const defaultGetAreaColorFromValue = (value) => {
  if (value == null) return exports["colors-invisible"];
  if (value < INDICATOR_THRESHOLD_LOW) return exports["colors-success"];
  if (value < INDICATOR_THRESHOLD_HIGH) return exports["colors-warning"];
  return exports["colors-danger"];
};

export const defaultGetLabelFromValue = (value) => {
  if (value < INDICATOR_THRESHOLD_LOW) return "Low risk";
  if (value < INDICATOR_THRESHOLD_MODERATE) return "Moderate risk";
  if (value < INDICATOR_THRESHOLD_HIGH) return "High risk";
  return "Very high risk";
};

export const defaultGetTooltipColorCumulativeReturns = (name, value) => {
  if (name !== EXPOSURE_PROPERTY_NAME) return;
  if (value == null) return exports["colors-grey"];
  if (value < EXPOSURE_THRESHOLD_LOW) return exports["colors-danger"];
  if (value < EXPOSURE_THRESHOLD_HIGH) return exports["colors-warning"];
  return exports["colors-success"];
};

export const defaultGetRedGradientAreaColorFromValue = (value) => {
  if (value == null) return exports["colors-invisible"];
  if (value < INDICATOR_THRESHOLD_LOW) return valueToOpacity(0);
  if (value < INDICATOR_THRESHOLD_MODERATE) return valueToOpacity(0.2);
  if (value < INDICATOR_THRESHOLD_HIGH) return valueToOpacity(0.4);
  return valueToOpacity(0.6);
};

const vega = {
  id: "vega",
  storeModule: "vegaIndicator",
  name: "Vega",
  subtitle: "Volatility Hedging Indicator",
  description:
    "Volatility is the only asset whose value increases during market crashes with a very high probability. Thus, market volatility can be a formidable predictor of crashes. Nevertheless, the timing necessary to master this type of signal dynamics requires a high level of expertise and practice. Alquant is proud to offer all types of investors its own quantitative equity exposure indicator relying on volatility analysis. Entirely based on scientific methods and thus avoiding any human bias, this indicator is designed to anticipate market corrections.",
  illustrationPath: require("@/assets/images/pages/titles/indicators/vega/title.svg"),
  knowledgeBaseComponentName: "VegaIndicator",
  icon: "ChevronsUpIcon",
  events: [
    EVENT_FINANCIAL_CRISIS,
    EVENT_EURO_CRISIS,
    EVENT_DECEMBER_2018_CRISIS,
    EVENT_COVID19_CRISIS,
  ],
  info: [
    {
      name: "Focus",
      value: "Trend of implied volatility metrics",
    },
    {
      name: "Type",
      value: "General",
      tooltip: "The indicator won't change if you change the underlying",
    },
    {
      name: "When does it perform well",
      value: "Sharp and brutal panic declines lasting few days (e.g. COVID-19, October 2008)",
    },
    {
      name: "When does it not perform well",
      value:
        "Bull or side markets with some day drops immediately followed by rebound the next day (e.g. 2015 or 2021)",
    },
    {
      name: "Reactivity",
      value: "Fast",
      tooltip: "Can go from low risk to very high risk in a few days",
    },
  ],
  stats: {
    time_spent: [
      {
        period: "Low risk",
        values: [{ index_name: "Vega", value: 0.65 }],
      },
      {
        period: "Moderate risk",
        values: [{ index_name: "Vega", value: 0.08 }],
      },
      {
        period: "High risk",
        values: [{ index_name: "Vega", value: 0.07 }],
      },
      {
        period: "Very high risk",
        values: [{ index_name: "Vega", value: 0.2 }],
      },
    ],
    annualized_return: [
      {
        period: "Low risk",
        values: [{ index_name: "Vega", value: 0.18 }],
      },
      {
        period: "Moderate risk",
        values: [{ index_name: "Vega", value: 0.22 }],
      },
      {
        period: "High risk",
        values: [{ index_name: "Vega", value: -0.08 }],
      },
      {
        period: "Very high risk",
        values: [{ index_name: "Vega", value: -0.12 }],
      },
    ],
    annualized_vola: [
      {
        period: "Low risk",
        values: [{ index_name: "Vega", value: 0.17 }],
      },
      {
        period: "Moderate risk",
        values: [{ index_name: "Vega", value: 0.2 }],
      },
      {
        period: "High risk",
        values: [{ index_name: "Vega", value: 0.19 }],
      },
      {
        period: "Very high risk",
        values: [{ index_name: "Vega", value: 0.29 }],
      },
    ],
  },
  timelineData: [
    {
      color: "primary",
      icon: "StarIcon",
      title: "Indicator creation",
      desc: "Creation of the Vega Indicator (out-of-sample).",
      time: "22 Oct 2018",
    },
    {
      color: "grey",
      icon: "PlusIcon",
      title: "Indicator release",
      desc: "The Vega Indicator was made publicly available on our platform.",
      time: "8 Apr 2020",
    },
    {
      color: "grey",
      icon: "PlusIcon",
      title: "Indicator update",
      desc: 'The Vega indicator is no longer a binary indicator, as it can take the values 0%, 25%, 50%, 75% and 100% as the risk level. This allows for a more granular view of the trend in implied volatility metrics. Note: This change is only a "rounding" change that has no material impact on performance.',
      time: "4 Apr 2022",
    },
  ],
  metaDescription:
    "The Vega indicator analyses various volatility indices to deduce whether their movements describe an underlying panic in the markets' short-term evolution.",
  metaKeywords: "vega, volatility, vix, crash, crisis",
  // We want to only show red color in the area graph otherwise it looks weird and the
  // green color is sometimes shown when it shouldn't because of how the graph is built
  // with d3.
  getAreaColorFromValue: (value) => {
    if (value == null) return exports["colors-invisible"];
    return exports["colors-danger"];
  },
};

const macroeconomic = {
  id: "macroeconomic",
  storeModule: "macroeconomicIndicator",
  name: "Macroeconomic",
  subtitle: "Global Economy Indicator",
  description:
    "The Macroeconomic indicator is keeping a bird-perspective of the real global economy (macroeconomic perspective) and not just focusing on financial markets, which is key to be able to identify financial bubbles and exuberances. It is based on non-financial data and brings an alternative outlook of equity markets.",
  illustrationPath: require("@/assets/images/pages/titles/indicators/macroeconomic/title.svg"),
  knowledgeBaseComponentName: "MacroeconomicIndicator",
  icon: "GlobeIcon",
  events: [
    EVENT_FINANCIAL_CRISIS,
    EVENT_EURO_CRISIS,
    EVENT_DECEMBER_2018_CRISIS,
    EVENT_COVID19_CRISIS,
  ],
  info: [
    {
      name: "Focus",
      value: "Leading economic indicators and high-frequency real economic data",
    },
    {
      name: "Type",
      value: "General",
      tooltip: "The indicator won't change if you change the underlying",
    },
    {
      name: "When does it perform well",
      value:
        "During periods of prolonged drawdowns driven by macroeconomic events such as economic recessions or contractions (financial crisis, dotcom crisis)",
    },
    {
      name: "When does it not perform well",
      value:
        "During fast meltdown or melt-up induced by exogenous forces (central bank and government decisions or sudden market participants euphoria)",
    },
    {
      name: "Reactivity",
      value: "Fast",
      tooltip: "Can go from low risk to very high risk in a few days",
    },
  ],
  stats: {
    time_spent: [
      {
        period: "Low risk",
        values: [{ index_name: "Macroeconomic", value: 0.2 }],
      },
      {
        period: "Moderate risk",
        values: [{ index_name: "Macroeconomic", value: 0.21 }],
      },
      {
        period: "High risk",
        values: [{ index_name: "Macroeconomic", value: 0.34 }],
      },
      {
        period: "Very high risk",
        values: [{ index_name: "Macroeconomic", value: 0.25 }],
      },
    ],
    annualized_return: [
      {
        period: "Low risk",
        values: [{ index_name: "Macroeconomic", value: 0.29 }],
      },
      {
        period: "Moderate risk",
        values: [{ index_name: "Macroeconomic", value: 0.25 }],
      },
      {
        period: "High risk",
        values: [{ index_name: "Macroeconomic", value: 0.09 }],
      },
      {
        period: "Very high risk",
        values: [{ index_name: "Macroeconomic", value: -0.12 }],
      },
    ],
    annualized_vola: [
      {
        period: "Low risk",
        values: [{ index_name: "Macroeconomic", value: 0.14 }],
      },
      {
        period: "Moderate risk",
        values: [{ index_name: "Macroeconomic", value: 0.14 }],
      },
      {
        period: "High risk",
        values: [{ index_name: "Macroeconomic", value: 0.17 }],
      },
      {
        period: "Very high risk",
        values: [{ index_name: "Macroeconomic", value: 0.31 }],
      },
    ],
  },
  timelineData: [
    {
      color: "primary",
      icon: "StarIcon",
      title: "Indicator creation",
      desc: "Creation of the Macroeconomic Indicator (out-of-sample).",
      time: "14 May 2020",
    },
    {
      color: "grey",
      icon: "PlusIcon",
      title: "Indicator release",
      desc: "The Macroeconomic Indicator was made publicly available on our platform.",
      time: "15 Apr 2021",
    },
    {
      color: "grey",
      icon: "PlusIcon",
      title: "Indicator update",
      desc: 'The Macroeconomic Indicator can no longer assume arbitrary values between 0% and 100%, but only the values 0%, 25%, 50%, 75%, and 100% as risk levels. Note: This change is only a "rounding" change that allows to reduce the number of rebalancing without material impact on performance.',
      time: "4 Apr 2022",
    },
  ],
  metaDescription:
    "The Macroeconomic indicator aggregates & analyses signals of economic activity to provide a reactive investment signal quantifying the global economic health.",
  metaKeywords: "macroeconomic, economy, global, world, crisis",
};

const credit = {
  id: "credit",
  storeModule: "creditIndicator",
  name: "Credit",
  subtitle: "High Bond Yields Indicator",
  description:
    "The Credit indicator tracks credit/lending conditions which are often considered as a leading indicator of the equity market. Why? Because it is often the case that when lending dries up (credit/lending conditions are unfavorable), all the bad stuff happens. Or in Warren Buffet's words: \"Only when the tide goes out do you discover who's been swimming naked.\" Indeed, in unfavorable credit environments companies living on debt are unable to gain more financing and defaults or even bankruptcies happen, which in turn negatively impact the overall equity market and especially small caps.",
  illustrationPath: require("@/assets/images/pages/titles/indicators/credit/title.svg"),
  knowledgeBaseComponentName: "CreditIndicator",
  icon: "CreditCardIcon",
  events: [
    EVENT_FINANCIAL_CRISIS,
    EVENT_EURO_CRISIS,
    EVENT_DECEMBER_2018_CRISIS,
    EVENT_COVID19_CRISIS,
  ],
  info: [
    {
      name: "Focus",
      value: "Credit spread",
      tooltip: "Difference between borrowing rates of enterprise and governments",
    },
    {
      name: "Type",
      value: "General",
      tooltip: "The indicator won't change if you change the underlying",
    },
    {
      name: "When does it perform well",
      value:
        "During periods of prolonged drawdowns driven by major events and general financial conditions (financial crisis, dotcom crisis)",
    },
    {
      name: "When does it not perform well",
      value:
        "During fast meltdown or melt-up induced by exogenous forces (central bank and government decisions or sudden market participants euphoria)",
    },
    {
      name: "Reactivity",
      value: "Moderate",
      tooltip: "Can go from low risk to very high risk within a few weeks",
    },
  ],
  stats: {
    time_spent: [
      {
        period: "Low risk",
        values: [{ index_name: "Credit", value: 0.36 }],
      },
      {
        period: "Moderate risk",
        values: [{ index_name: "Credit", value: 0.2 }],
      },
      {
        period: "High risk",
        values: [{ index_name: "Credit", value: 0.15 }],
      },
      {
        period: "Very high risk",
        values: [{ index_name: "Credit", value: 0.29 }],
      },
    ],
    annualized_return: [
      {
        period: "Low risk",
        values: [{ index_name: "Credit", value: 0.13 }],
      },
      {
        period: "Moderate risk",
        values: [{ index_name: "Credit", value: 0.1 }],
      },
      {
        period: "High risk",
        values: [{ index_name: "Credit", value: 0.19 }],
      },
      {
        period: "Very high risk",
        values: [{ index_name: "Credit", value: -0.02 }],
      },
    ],
    annualized_vola: [
      {
        period: "Low risk",
        values: [{ index_name: "Credit", value: 0.12 }],
      },
      {
        period: "Moderate risk",
        values: [{ index_name: "Credit", value: 0.14 }],
      },
      {
        period: "High risk",
        values: [{ index_name: "Credit", value: 0.16 }],
      },
      {
        period: "Very high risk",
        values: [{ index_name: "Credit", value: 0.32 }],
      },
    ],
  },
  timelineData: [
    {
      color: "primary",
      icon: "StarIcon",
      title: "Indicator creation",
      desc: "Creation of the Credit Indicator (out-of-sample).",
      time: "22 Oct 2018",
    },
    {
      color: "grey",
      icon: "PlusIcon",
      title: "Indicator release",
      desc: "The Credit Indicator was made publicly available on our platform.",
      time: "11 Jun 2020",
    },
    {
      color: "grey",
      icon: "PlusIcon",
      title: "Indicator update",
      desc: 'The Credit Indicator can no longer assume arbitrary values between 0% and 100%, but only the values 0%, 25%, 50%, 75%, and 100% as risk levels. Note: This change is only a "rounding" change that allows to reduce the number of rebalancing without material impact on performance.',
      time: "4 Apr 2022",
    },
  ],
  metaDescription:
    "The Credit indicator is an indicator that analyzes the dynamics and trend of credit spreads through lending conditions.",
  metaKeywords: "credit, lending, borrowing, interest, rate, bubble",
};

const spike = {
  id: "spike",
  storeModule: "spikeIndicator",
  name: "Spike",
  subtitle: "Volatility Risk Indicator",
  description:
    "The VIX (Cboe Volatility Index) is an index based on S&P 500 options that quantifies investors’ expectation of market volatility. As high volatility is a recurrent symptom of stock market crashes, the VIX can be interpreted as an index of market fear for the coming month. The computation of the VIX is applied to several time horizons through a multitude of indices such as the VIX9D (Cboe 9-Day Volatility Index) or the VIX3M (Cboe 3-Month Volatility Index). The Spike indicator takes a broad range of such S&P 500 market volatility measures and combines them to provide a robust and highly responsive risk signal that can be used to dynamically adjust equity exposures in short and mid term horizons.",
  illustrationPath: require("@/assets/images/pages/titles/indicators/spike/title.svg"),
  knowledgeBaseComponentName: "SpikeIndicator",
  icon: "AlertOctagonIcon",
  events: [
    EVENT_FINANCIAL_CRISIS,
    EVENT_EURO_CRISIS,
    EVENT_DECEMBER_2018_CRISIS,
    EVENT_COVID19_CRISIS,
  ],
  info: [
    {
      name: "Focus",
      value: "Volatility metrics",
    },
    {
      name: "Type",
      value: "General",
      tooltip: "The indicator won't change if you change the underlying",
    },
    {
      name: "When does it perform well",
      value: "Sharp and brutal panic declines lasting few days (e.g. COVID-19, October 2008)",
    },
    {
      name: "When does it not perform well",
      value:
        "Bull or side markets with some day drops immediately followed by rebound the next day (e.g. 2015 or 2021)",
    },
    {
      name: "Reactivity",
      value: "Very fast",
      tooltip: "Can go from low risk to very high risk in one day",
    },
  ],
  stats: {
    time_spent: [
      {
        period: "Low risk",
        values: [{ index_name: "Spike", value: 0.62 }],
      },
      {
        period: "Moderate risk",
        values: [{ index_name: "Spike", value: 0.2 }],
      },
      {
        period: "High risk",
        values: [{ index_name: "Spike", value: 0.1 }],
      },
      {
        period: "Very high risk",
        values: [{ index_name: "Spike", value: 0.08 }],
      },
    ],
    annualized_return: [
      {
        period: "Low risk",
        values: [{ index_name: "Spike", value: 0.09 }],
      },
      {
        period: "Moderate risk",
        values: [{ index_name: "Spike", value: -0.003 }],
      },
      {
        period: "High risk",
        values: [{ index_name: "Spike", value: 0.39 }],
      },
      {
        period: "Very high risk",
        values: [{ index_name: "Spike", value: 0.001 }],
      },
    ],
    annualized_vola: [
      {
        period: "Low risk",
        values: [{ index_name: "Spike", value: 0.12 }],
      },
      {
        period: "Moderate risk",
        values: [{ index_name: "Spike", value: 0.21 }],
      },
      {
        period: "High risk",
        values: [{ index_name: "Spike", value: 0.24 }],
      },
      {
        period: "Very high risk",
        values: [{ index_name: "Spike", value: 0.51 }],
      },
    ],
  },
  timelineData: [
    {
      color: "primary",
      icon: "StarIcon",
      title: "Indicator creation",
      desc: "Creation of the Spike Indicator (out-of-sample).",
      time: "11 Sep 2020",
    },
    {
      color: "grey",
      icon: "PlusIcon",
      title: "Indicator release",
      desc: "The Spike Indicator was made publicly available on our platform",
      time: "16 Jun 2021",
    },
    {
      color: "grey",
      icon: "PlusIcon",
      title: "Indicator update",
      desc: 'The Spike Indicator can no longer assume arbitrary values between 0% and 100%, but only the values 0%, 25%, 50%, 75%, and 100% as risk levels. Note: This change is only a "rounding" change that allows to reduce the number of rebalancing without material impact on performance.',
      time: "4 Apr 2022",
    },
  ],
  metaDescription:
    "The Spike indicator aims to aid portfolio hedging by providing a view of the daily implied volatility of the S&P 500 index & quantifying the associated risk.",
  metaKeywords: "spike, volatility, vix, short-term",
};

const crossvol = {
  id: "crossvol",
  storeModule: "CrossvolIndicator",
  name: "Crossvol",
  subtitle: "Broad Volatility Risk Indicator",
  description:
    "Most major economic and financial crashes have started with panic sparkling in one asset class before spreading to the other financial markets. The Crossvol indicator offers a comprehensive view of broad financial market stress by aggregating volatility measures from various asset classes including equities, commodities, bonds and currencies, and combining them into a single risk measure. This diversification provides an exhaustive understanding of the daily stress among markets and aims to deliver a robust edge on anticipating future crises.",
  illustrationPath: require("@/assets/images/pages/titles/indicators/crossvol/title.svg"),
  knowledgeBaseComponentName: "CrossvolIndicator",
  icon: "SlidersIcon",
  events: [
    EVENT_FINANCIAL_CRISIS,
    EVENT_EURO_CRISIS,
    EVENT_DECEMBER_2018_CRISIS,
    EVENT_COVID19_CRISIS,
  ],
  info: [
    {
      name: "Focus",
      value: "Volatility metrics",
    },
    {
      name: "Type",
      value: "General",
      tooltip: "The indicator won't change if you change the underlying",
    },
    {
      name: "When does it perform well",
      value: "Broad economic or financial crisis affecting many asset classes",
    },
    {
      name: "When does it not perform well",
      value:
        "Events that are inherent to a specific asset and have a low impact on other asset classes",
    },
    {
      name: "Reactivity",
      value: "Very fast",
      tooltip: "Can go from low risk to very high risk in one day",
    },
  ],
  stats: {
    time_spent: [
      {
        period: "Low risk",
        values: [{ index_name: "Crossvol", value: 0.69 }],
      },
      {
        period: "Moderate risk",
        values: [{ index_name: "Crossvol", value: 0.15 }],
      },
      {
        period: "High risk",
        values: [{ index_name: "Crossvol", value: 0.09 }],
      },
      {
        period: "Very high risk",
        values: [{ index_name: "Crossvol", value: 0.07 }],
      },
    ],
    annualized_return: [
      {
        period: "Low risk",
        values: [{ index_name: "Crossvol", value: 0.0745 }],
      },
      {
        period: "Moderate risk",
        values: [{ index_name: "Crossvol", value: 0.1681 }],
      },
      {
        period: "High risk",
        values: [{ index_name: "Crossvol", value: 0.5433 }],
      },
      {
        period: "Very high risk",
        values: [{ index_name: "Crossvol", value: -0.442 }],
      },
    ],
    annualized_vola: [
      {
        period: "Low risk",
        values: [{ index_name: "Crossvol", value: 0.1246 }],
      },
      {
        period: "Moderate risk",
        values: [{ index_name: "Crossvol", value: 0.2246 }],
      },
      {
        period: "High risk",
        values: [{ index_name: "Crossvol", value: 0.2837 }],
      },
      {
        period: "Very high risk",
        values: [{ index_name: "Crossvol", value: 0.4839 }],
      },
    ],
  },
  timelineData: [
    {
      color: "primary",
      icon: "StarIcon",
      title: "Indicator creation",
      desc: "Creation of the Crossvol Indicator (out-of-sample).",
      time: "10 Jun 2022",
    },
    {
      color: "grey",
      icon: "PlusIcon",
      title: "Indicator release",
      desc: "The Crossvol Indicator was made publicly available on our platform.",
      time: "6 Dec 2022",
    },
  ],
  metaDescription:
    "The Crossvol indicator offers a comprehensive view of broad financial market stress by aggregating volatility measures from various asset classes.",
  metaKeywords: "crossvol, volatility, broad, crisis, vix, asset",
};

const netLiquidity = {
  id: "net-liquidity",
  storeModule: "netLiquidityIndicator",
  name: "Net Liquidity",
  subtitle: "Central Banks Net Liquidity Indicator",
  description:
    "The Net Liquidity Indicator evaluates major global central banks' balance sheet data to estimate resulting liquidity and potential market risk.",
  illustrationPath: require("@/assets/images/pages/titles/indicators/net-liquidity/title.svg"),
  knowledgeBaseComponentName: "NetLiquidityIndicator",
  icon: "DropletIcon",
  events: [
    EVENT_FINANCIAL_CRISIS,
    EVENT_EURO_CRISIS,
    EVENT_DECEMBER_2018_CRISIS,
    EVENT_COVID19_CRISIS,
  ],
  info: [
    {
      name: "Focus",
      value: "Central banks' balance sheets and resulting liquidity",
    },
    {
      name: "Type",
      value: "General",
      tooltip: "The indicator won't change if you change the underlying",
    },
    {
      name: "When does it perform well",
      value: "During periods of strong monetary intervention by central banks",
    },
    {
      name: "When does it not perform well",
      value:
        "During short-term periods of high volatility related to exogenous factors  not covered by central banks' intervention",
    },
    {
      name: "Reactivity",
      value: "Fast",
      tooltip: "Can go from low risk to very high risk in a few days",
    },
  ],
  stats: {
    time_spent: [
      {
        period: "Low risk",
        values: [{ index_name: "Net Liquidity", value: 0.49 }],
      },
      {
        period: "Moderate risk",
        values: [{ index_name: "Net Liquidity", value: 0.1 }],
      },
      {
        period: "High risk",
        values: [{ index_name: "Net Liquidity", value: 0.08 }],
      },
      {
        period: "Very high risk",
        values: [{ index_name: "Net Liquidity", value: 0.33 }],
      },
    ],
    annualized_return: [
      {
        period: "Low risk",
        values: [{ index_name: "Net Liquidity", value: 0.3774 }],
      },
      {
        period: "Moderate risk",
        values: [{ index_name: "Net Liquidity", value: 0.0 }],
      },
      {
        period: "High risk",
        values: [{ index_name: "Net Liquidity", value: -0.1499 }],
      },
      {
        period: "Very high risk",
        values: [{ index_name: "Net Liquidity", value: -0.1194 }],
      },
    ],
    annualized_vola: [
      {
        period: "Low risk",
        values: [{ index_name: "Net Liquidity", value: 0.165 }],
      },
      {
        period: "Moderate risk",
        values: [{ index_name: "Net Liquidity", value: 0.182 }],
      },
      {
        period: "High risk",
        values: [{ index_name: "Net Liquidity", value: 0.315 }],
      },
      {
        period: "Very high risk",
        values: [{ index_name: "Net Liquidity", value: 0.209 }],
      },
    ],
  },
  timelineData: [
    {
      color: "primary",
      icon: "StarIcon",
      title: "Indicator creation",
      desc: "Creation of the Net Liquidity Indicator (out-of-sample).",
      time: "17 Jul 2023",
    },
    {
      color: "grey",
      icon: "PlusIcon",
      title: "Indicator release",
      desc: "The Net Liquidity Indicator was made publicly available on our platform.",
      time: "19 Sep 2023",
    },
  ],
  metaDescription: "TBD",
  metaKeywords: "net, liquidity, central, bank, fed, ecb",
};

const monetaryPolicy = {
  id: "monetary-policy",
  storeModule: "monetaryPolicyIndicator",
  allowedRoles: [Role.admin, Role.superAdmin, Role.employee],
  name: "Monetary Policy",
  subtitle: "Interest Rates Risk Indicator",
  description:
    "The FED's monetary policy and more specifically the entire US yield curve play a major role in the economy and contain important information and expectations about the future economy. The goal of the Monetary Policy indicator is to tell in which regime the economy currently is, to adapt investors’ equity exposure.",
  illustrationPath: require("@/assets/images/pages/titles/indicators/monetary-policy/title.svg"),
  knowledgeBaseComponentName: "MonetaryPolicyIndicator",
  icon: "DollarSignIcon",
  events: [
    EVENT_FINANCIAL_CRISIS,
    EVENT_EURO_CRISIS,
    EVENT_DECEMBER_2018_CRISIS,
    EVENT_COVID19_CRISIS,
  ],
  timelineData: [
    {
      color: "primary",
      icon: "StarIcon",
      title: "Indicator creation",
      desc: "Creation of the Monetary Policy Indicator (out-of-sample).",
      time: "22 Oct 2018",
    },
    {
      color: "grey",
      icon: "PlusIcon",
      title: "Indicator release",
      desc: "The Monetary Policy Indicator was made publicly available on our platform.",
      time: "21 Apr 2020",
    },
  ],
};

const momentum = {
  id: "momentum",
  storeModule: "momentumIndicator",
  name: "Momentum",
  subtitle: "Trend Following Indicator",
  description:
    "Take advantage of investor herding by using our quantitative momentum indicator and get notified when a certain market loses momentum. We use a combination of windows to build different moving average crossovers in order to get a diversified view of the current momentum, and we aggregate them into one simple risk indicator.",
  illustrationPath: require("@/assets/images/pages/titles/indicators/momentum/title.svg"),
  knowledgeBaseComponentName: "MomentumIndicator",
  icon: "TrendingUpIcon",
  events: [
    EVENT_FINANCIAL_CRISIS,
    EVENT_EURO_CRISIS,
    EVENT_DECEMBER_2018_CRISIS,
    EVENT_COVID19_CRISIS,
  ],
  info: [
    {
      name: "Focus",
      value: "Price trends",
    },
    {
      name: "Type",
      value: "Asset-specific",
      tooltip: "The indicator will change depending on the underlying selected",
    },
    {
      name: "When does it perform well",
      value: "During extended periods of drawdowns (e.g. dotcom bubble, global financial crisis)",
    },
    {
      name: "When does it not perform well",
      value:
        "Sharp and rapid drawdowns lasting few days or weeks not followed by further declines (Black Monday 1987, COVID-19)",
    },
    {
      name: "Reactivity",
      value: "Slow",
      tooltip: "Can go from low risk to very high risk in one month",
    },
  ],
  stats: {
    time_spent: [
      {
        period: "Low risk",
        values: [{ index_name: "Momentum", value: 0.69 }],
      },
      {
        period: "Moderate risk",
        values: [{ index_name: "Momentum", value: 0.08 }],
      },
      {
        period: "High risk",
        values: [{ index_name: "Momentum", value: 0.04 }],
      },
      {
        period: "Very high risk",
        values: [{ index_name: "Momentum", value: 0.19 }],
      },
    ],
    annualized_return: [
      {
        period: "Low risk",
        values: [{ index_name: "Momentum", value: 0.095 }],
      },
      {
        period: "Moderate risk",
        values: [{ index_name: "Momentum", value: 0.115 }],
      },
      {
        period: "High risk",
        values: [{ index_name: "Momentum", value: 0.125 }],
      },
      {
        period: "Very high risk",
        values: [{ index_name: "Momentum", value: 0.065 }],
      },
    ],
    annualized_vola: [
      {
        period: "Low risk",
        values: [{ index_name: "Momentum", value: 0.13 }],
      },
      {
        period: "Moderate risk",
        values: [{ index_name: "Momentum", value: 0.21 }],
      },
      {
        period: "High risk",
        values: [{ index_name: "Momentum", value: 0.25 }],
      },
      {
        period: "Very high risk",
        values: [{ index_name: "Momentum", value: 0.37 }],
      },
    ],
  },
  timelineData: [
    {
      color: "primary",
      icon: "StarIcon",
      title: "Indicator creation",
      desc: "Creation of the Momentum Indicator (out-of-sample).",
      time: "22 Oct 2018",
    },
    {
      color: "primary",
      icon: "PlusIcon",
      title: "Indicator release",
      desc: "The Momentum Indicator was made publicly available on our platform.",
      time: "21 Apr 2020",
    },
    {
      color: "grey",
      icon: "PlusIcon",
      title: "Indicator update",
      desc: 'The Momentum Indicator can no longer assume arbitrary values between 0% and 100%, but only the values 0%, 25%, 50%, 75%, and 100% as risk levels. Note: This change is only a "rounding" change that allows to reduce the number of rebalancing without material impact on performance.',
      time: "4 Apr 2022",
    },
  ],
  metaDescription:
    "The Momentum indicator assesses the dynamics of the underlying asset over different time horizons and aggregates them to estimate the underlying risk.",
  metaKeywords: "momentum, trend, risk, bull, bear",
};

const pulse = {
  id: "pulse",
  storeModule: "pulseIndicator",
  name: "Pulse",
  subtitle: "Pulse Indicator",
  description:
    'The Pulse indicator relies on <a href="https://edition.cnn.com/markets/fear-and-greed">the CNN Fear & Greed Index</a> and measures stock market sentiment, with scores from 0 (maximum fear) to 100 (maximum greed). It combines seven indicators like market momentum and volatility, updating continuously to help investors gauge market emotions and make more informed decisions. Alquant has developed an indicator that analyses the dynamics of the Fear & Greed index in order to anticipate possible sudden changes in its trend, reflecting a change in investor behaviour that could lead to widespread panic on the equity markets.',
  illustrationPath: require("@/assets/images/pages/titles/indicators/pulse/title.svg"),
  knowledgeBaseComponentName: "PulseIndicator",
  icon: "MoveIcon",
  events: [
    EVENT_FINANCIAL_CRISIS,
    EVENT_EURO_CRISIS,
    EVENT_DECEMBER_2018_CRISIS,
    EVENT_COVID19_CRISIS,
  ],
  info: [
    {
      name: "Focus",
      value: "CNN Business Fear & Greed Index",
    },
    {
      name: "Type",
      value: "General",
      tooltip: "The indicator won't change if you change the underlying",
    },
    {
      name: "When does it perform well",
      value:
        "During periods of major reversal in investor behaviour, reflecting sudden nervousness",
    },
    {
      name: "When does it not perform well",
      value:
        "During periods when investors’ shift in behaviour do not result in significant market moves",
    },
    {
      name: "Reactivity",
      value: "Fast",
      tooltip: "Can go from low risk to very high risk in a few days",
    },
  ],
  stats: {
    time_spent: [
      {
        period: "Low risk",
        values: [{ index_name: "Pulse", value: 0.5939 }],
      },
      {
        period: "Moderate risk",
        values: [{ index_name: "Pulse", value: 0.0324 }],
      },
      {
        period: "High risk",
        values: [{ index_name: "Pulse", value: 0.0231 }],
      },
      {
        period: "Very high risk",
        values: [{ index_name: "Pulse", value: 0.3506 }],
      },
    ],
    annualized_return: [
      {
        period: "Low risk",
        values: [{ index_name: "Pulse", value: 0.0884 }],
      },
      {
        period: "Moderate risk",
        values: [{ index_name: "Pulse", value: 0.0006 }],
      },
      {
        period: "High risk",
        values: [{ index_name: "Pulse", value: 0.0019 }],
      },
      {
        period: "Very high risk",
        values: [{ index_name: "Pulse", value: -0.0056 }],
      },
    ],
    annualized_vola: [
      {
        period: "Low risk",
        values: [{ index_name: "Pulse", value: 0.1787 }],
      },
      {
        period: "Moderate risk",
        values: [{ index_name: "Pulse", value: 0.2561 }],
      },
      {
        period: "High risk",
        values: [{ index_name: "Pulse", value: 0.2213 }],
      },
      {
        period: "Very high risk",
        values: [{ index_name: "Pulse", value: 0.2328 }],
      },
    ],
  },
  timelineData: [
    {
      color: "primary",
      icon: "StarIcon",
      title: "Indicator creation",
      desc: "Creation of the Pulse Indicator (out-of-sample).",
      time: "28 August 2024",
    },
  ],
  metaDescription:
    "The Pulse indicator relies on the CNN Fear & Greed Index and measures stock market sentiment.",
  metaKeywords: "pulse, fear, greed",
};

const rotation = {
  id: "rotation",
  storeModule: "rotationIndicator",
  allowedRoles: [Role.admin, Role.superAdmin, Role.employee],
  name: "Rotation",
  subtitle: "Rotation Indicator",
  description:
    "The Rotation Indicator is based on cyclical dynamics between growth sectors and low-volatility sectors.<br>The aim of this indicator is to detect changes in the spread between these two sectors, which is often the precursor of a future or current recession.",
  illustrationPath: require("@/assets/images/pages/titles/indicators/rotation/title.svg"),
  knowledgeBaseComponentName: "RotationIndicator",
  icon: "RotateCwIcon",
  events: [
    EVENT_FINANCIAL_CRISIS,
    EVENT_EURO_CRISIS,
    EVENT_DECEMBER_2018_CRISIS,
    EVENT_COVID19_CRISIS,
  ],
  info: [
    {
      name: "Focus",
      value: "Equity Sectors dynamics",
    },
    {
      name: "Type",
      value: "General",
      tooltip: "The indicator won't change if you change the underlying",
    },
    {
      name: "When does it perform well",
      value: "During periods of economic shift and financial uncertainty",
    },
    {
      name: "When does it not perform well",
      value: "During short-term highly volatile periods",
    },
    {
      name: "Reactivity",
      value: "Moderate",
      tooltip: "Can go from low risk to very high risk within a few weeks",
    },
  ],
  stats: {
    time_spent: [
      {
        period: "Low risk",
        values: [{ index_name: "Rotation", value: 0.5579 }],
      },
      {
        period: "Moderate risk",
        values: [{ index_name: "Rotation", value: 0.0858 }],
      },
      {
        period: "High risk",
        values: [{ index_name: "Rotation", value: 0.1183 }],
      },
      {
        period: "Very high risk",
        values: [{ index_name: "Rotation", value: 0.2379 }],
      },
    ],
    annualized_return: [
      {
        period: "Low risk",
        values: [{ index_name: "Rotation", value: 0.0978 }],
      },
      {
        period: "Moderate risk",
        values: [{ index_name: "Rotation", value: -0.0009 }],
      },
      {
        period: "High risk",
        values: [{ index_name: "Rotation", value: -0.0101 }],
      },
      {
        period: "Very high risk",
        values: [{ index_name: "Rotation", value: 0.0177 }],
      },
    ],
    annualized_vola: [
      {
        period: "Low risk",
        values: [{ index_name: "Rotation", value: 0.1474 }],
      },
      {
        period: "Moderate risk",
        values: [{ index_name: "Rotation", value: 0.1515 }],
      },
      {
        period: "High risk",
        values: [{ index_name: "Rotation", value: 0.2203 }],
      },
      {
        period: "Very high risk",
        values: [{ index_name: "Rotation", value: 0.249 }],
      },
    ],
  },
  timelineData: [
    {
      color: "primary",
      icon: "StarIcon",
      title: "Indicator creation",
      desc: "Creation of the Rotation Indicator (out-of-sample).",
      time: "31 March 2025",
    },
  ],
  metaDescription:
    "The Rotation Indicator tracks the spread between growth and low-volatility sectors to identify early signs of economic slowdowns or recessions.",
  metaKeywords: "rotation, recession, economic cycles, sector spread, cyclical dynamics",
};

export const ALQUANT_INDICATORS_DETAILS = [
  vega,
  macroeconomic,
  credit,
  spike,
  crossvol,
  netLiquidity,
  monetaryPolicy,
  momentum,
  pulse,
  rotation,
];

export const ALQUANT_INDICATORS = ALQUANT_INDICATORS_DETAILS.map((details) => details.storeModule);
