import "firebase/compat/firestore";

import firebase from "firebase/compat/app";
import { firestoreAction } from "vuexfire";

import { getNested } from "@/store/utils.js";

const DEFAULT_BENCHMARK_FOR_NOTIFICATION = {
  active: true,
  currency: "USD",
  exchange: "predefined",
  id: "GSPC.INDX",
  name: "S&P 500",
  start_date: "1980-12-12",
  ticker: "GSPC.INDX",
};

export default {
  namespaced: true,
  state: {
    userInfo: null,
  },
  mutations: {
    FETCH_USER_INFO_FAILURE(state) {
      state.userInfo = undefined;
    },
  },
  actions: {
    bindUserInfoRef: firestoreAction(({ bindFirestoreRef, commit }) => {
      const currentUser = firebase.auth().currentUser;
      if (!currentUser) {
        return Promise.resolve();
      }

      return bindFirestoreRef(
        "userInfo",
        firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid)
      ).catch((error) => {
        // We need to rethrow the error if we hope to catch
        // it in sentry.
        // Note that we only do this if the user is still set,
        // as the most likely case is that we tried to log in the
        // user, it succeeded but we needed to sign out him, and
        // thus the binding promise above fail with a "Missing permission".
        // as it already started to get the link with Firestore
        // while we signed out the user.
        if (firebase.auth().currentUser) {
          commit("FETCH_USER_INFO_FAILURE");
          throw error;
        }
      });
    }),
    updateUserInfo(_, { newUserInfo }) {
      const userRef = firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid);
      return userRef.set(newUserInfo, { merge: true });
    },
    // Create new user document in firestore.
    createUserInfo(_, { uid, userInfo }) {
      return firebase
        .firestore()
        .collection("users")
        .doc(uid)
        .set(userInfo, { merge: true })
        .then(() => {
          // To help people get notified by us, and because they tend
          // to not find how to register to notifications, we register
          // new users by default to two notifications.
          return Promise.all(
            [
              ["alquant1", "Equity Risk Indicator"],
              ["vega", "Vega"],
            ].map((notif) => {
              // We need to create the doc for notif[0], otherwise it's
              // not "created", and just a path to follow, hence when we
              // want to iterate over it, we won't find it (because the
              // object itself doesn't exist).
              firebase
                .firestore()
                .collection("users")
                .doc(uid)
                .collection("notifications")
                .doc(notif[0])
                .set({}, { merge: true });

              const newNotif = firebase
                .firestore()
                .collection("users")
                .doc(uid)
                .collection("notifications")
                .doc(notif[0])
                .collection("userDefined")
                .doc();

              newNotif.set({
                underlying: DEFAULT_BENCHMARK_FOR_NOTIFICATION,
                name: "Default notification",
                notificationId: newNotif.id,
                rebalancingFrequency: "WEEKLY",
                rebalancingThreshold: 0.25,
              });
            })
          );
        });
    },
  },
  getters: {
    getUserInfo: (state) => {
      return state.userInfo;
    },
    getFirstName: (state) => {
      return getNested(state.userInfo, "firstName");
    },
    getLastName: (state) => {
      return getNested(state.userInfo, "lastName");
    },
    getEmail: (state) => {
      return getNested(state.userInfo, "email");
    },
    getCountry: (state) => {
      return getNested(state.userInfo, "country");
    },
    getInvestorProfile: (state) => {
      return getNested(state.userInfo, "investorProfile");
    },
    getCompany: (state) => {
      return getNested(state.userInfo, "company");
    },
    getTelNumber: (state) => {
      return getNested(state.userInfo, "telNumber");
    },
    getTelNumberCountryCode: (state) => {
      return getNested(state.userInfo, "telNumberCountryCode");
    },
    getHasAlreadySeenTour: (state) => {
      return getNested(state.userInfo, "hasAlreadySeenTour");
    },
  },
};
