// For more details visit:
// https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/documentation/development/firebaseIntegration.html

import "firebase/compat/analytics";
import "firebase/compat/auth";
import "firebase/compat/firestore";

import { logEvent } from "@firebase/analytics";
import firebase from "firebase/compat/app";
import Vue from "vue";

// Initialize Firebase
const config = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
};

firebase.initializeApp(config);
firebase.analytics();

// Create an alias
Vue.prototype.$analytics = firebase.analytics();

// The size of the request we send to google analytics is limited to
// max 2^14 characters, and if above, it will fail with a 413 error.
// To avoid this case, and to avoid user needing to check all logEvents
// he creates, we override the logEvent to reduce the size of the message
// as it's the biggest field we can have (as of 2022). Plus, the size of
// a field is max 100 characters anyway in google analytics, so it's not
// like we lose information by doing this.
Vue.prototype.$analytics.logEvent = function (eventName, eventParams, options) {
  if (eventParams?.message) {
    eventParams.message = eventParams.message.slice(0, 100);
  }

  logEvent(this._delegate, eventName, eventParams, options);
};
