import firebase from "firebase/compat/app";
import Vue from "vue";
import VueRouter from "vue-router";

import { hasAnyRoles, setAndGetUserRoles } from "@/acl";
import { Role } from "@/acl/roles";

import customIndicatorsRoutes from "./customIndicators";
import indicatorsRoutes from "./indicators";
import portfoliosRoutes from "./portfolios";
import toolsRoutes from "./tools";

Vue.use(VueRouter);

// =============================================================================
// HOME ROUTES
// =============================================================================

let mainLayoutRoutes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/Home.vue"),
  },
  // TODO: put it back when we have a way to make it fast enough without taking
  // all the resources of the backend.
  /*
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("../views/Dashboard.vue"),
  },
  */
  ...indicatorsRoutes,
  ...customIndicatorsRoutes,
  ...portfoliosRoutes,
  ...toolsRoutes,
];

// =============================================================================
// HELP ROUTES
// =============================================================================

// Add the help routes we configured.
mainLayoutRoutes = mainLayoutRoutes.concat([
  {
    path: "/help/knowledge-base",
    name: "help_knowledgeBase",
    component: () => import("../views/help/KnowledgeBase.vue"),
  },
  {
    path: "/help/knowledge-base/terms/:term",
    name: "help_knowledgeBase_term",
    component: () => import("../views/help/knowledgeBaseTerm/KnowledgeBaseTerm.vue"),
    props: true,
  },
]);

mainLayoutRoutes.push({
  path: "/help/videos",
  name: "help_videos",
  component: () => import("../views/help/Videos.vue"),
});

mainLayoutRoutes.push({
  path: "/help/faq",
  name: "help_faq",
  component: () => import("../views/help/Faq.vue"),
});

mainLayoutRoutes.push({
  path: "/help/contact",
  name: "help_contact",
  component: () => import("../views/help/Contact.vue"),
});

// =============================================================================
// ADMIN ROUTES
// =============================================================================

mainLayoutRoutes.push({
  path: "/admin/users",
  name: "admin_users",
  component: () => import("../views/admin/Users.vue"),
  meta: {
    authorize: [Role.admin, Role.superAdmin],
  },
});

// =============================================================================
// SETTINGS ROUTES
// =============================================================================

mainLayoutRoutes.push({
  path: "/settings/oveview",
  name: "settings_overview",
  component: () => import("../views/settings/Settings.vue"),
  meta: {
    authorize: [Role.insight],
  },
  props: {
    sectionId: "overview",
  },
});
mainLayoutRoutes.push({
  path: "/settings/edit-profile",
  name: "settings_editProfile",
  component: () => import("../views/settings/Settings.vue"),
  meta: {
    authorize: [Role.insight],
  },
  props: {
    sectionId: "editProfile",
  },
});
mainLayoutRoutes.push({
  path: "/settings/change-password",
  name: "settings_changePassword",
  component: () => import("../views/settings/Settings.vue"),
  meta: {
    authorize: [Role.insight],
  },
  props: {
    sectionId: "changePassword",
  },
});
mainLayoutRoutes.push({
  path: "/settings/delete-account",
  name: "settings_deleteAccount",
  component: () => import("../views/settings/Settings.vue"),
  meta: {
    authorize: [Role.insight],
  },
  props: {
    sectionId: "deleteAccount",
  },
});

const routes = [
  {
    // =============================================================================
    // MAIN LAYOUT ROUTES
    // =============================================================================
    path: "",
    component: () => import("../layouts/main/Main.vue"),
    children: mainLayoutRoutes,
  },
  // =============================================================================
  // PAGES
  // =============================================================================
  {
    path: "",
    component: () => import("@/layouts/full-page/FullPage.vue"),
    children: [
      // =============================================================================
      // Auth Routes
      // =============================================================================
      {
        path: "/auth/login",
        name: "page-login",
        component: () => import("@/views/auth/login/Login.vue"),
        meta: {
          authorize: [Role.guest],
        },
        props: true,
      },
      {
        path: "/auth/register",
        name: "page-register",
        component: () => import("@/views/auth/register/Register.vue"),
        meta: {
          authorize: [Role.guest],
        },
      },
      {
        path: "/auth/handled-by-firebase",
        beforeEnter(to) {
          // We want to keep the query params and add them to the Firebase URL.
          const allQueryParams = Object.keys(to.query).map((key) => `${key}=${to.query[key]}`);
          const queryParamsStr = allQueryParams.join("&");
          // Put the full page url including the protocol http(s) below
          const authDomain = process.env.VUE_APP_FIREBASE_AUTH_DOMAIN;
          window.location = `https://${authDomain}/__/auth/action?${queryParamsStr}`;
        },
      },
      {
        path: "/auth/reset-password-form",
        name: "reset-password-form",
        component: () => import("@/views/auth/resetPassword/ResetPasswordForm.vue"),
        meta: {
          authorize: [Role.guest],
        },
      },
      // =============================================================================
      // Compliance Routes
      // =============================================================================
      {
        path: "/compliance/terms-of-use",
        name: "compliance_termsofuse",
        component: () => import("@/views/compliance/TermsOfUse.vue"),
      },
      {
        path: "/compliance/privacy-policy",
        name: "compliance_privacypolicy",
        component: () => import("@/views/compliance/PrivacyPolicy.vue"),
      },
      {
        path: "/compliance/ombudsman",
        name: "compliance_ombudsman",
        component: () => import("@/views/compliance/Ombudsman.vue"),
      },
      // =============================================================================
      // Error Routes
      // =============================================================================
      {
        path: "/error-404",
        name: "page-error-404",
        component: () => import("@/views/errors/Error404.vue"),
      },
    ],
  },
  // Since we now don't have the server at the same URL as the client, let's redirect any
  // requests that are using the old URL, as some people maybe have old links.
  {
    path: "/api/*",
    beforeEnter(to) {
      // We want to keep the query params and add them to the Firebase URL.
      const allQueryParams = Object.keys(to.query).map((key) => `${key}=${to.query[key]}`);
      const queryParamsStr = allQueryParams.join("&");
      window.location = `${process.env.VUE_APP_API_URL}${to.path}?${queryParamsStr}`;
    },
  },
  {
    path: "*",
    redirect: "/error-404",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

/*
The custom claims from firebase allows us to store roles for the users,
roles that are linked to their subscriptions plans. The custom claims of
firebase comes with the token created during the log in/register, and is
refreshed every hour. If the user has a new subscription, we don't want
him to wait an hour to have access to his plan. Thus, whenever we arrive
on the platform, or reload, we will once "refresh" the token from firebase.
We do this only once, because it's extremely costly to request this
token, ~300ms, while using it without refresh is fast, ~1ms.
*/
let firstLoad = true;

router.beforeEach(async (to, from, next) => {
  // We refresh only once manually the token and we bind the preferences
  // of the user to its preferences saved in firestore.
  if (firstLoad && firebase.auth().currentUser != null) {
    await firebase.auth().currentUser.getIdTokenResult(true);
    firstLoad = false;
  }

  const { authorize } = to.meta;
  await setAndGetUserRoles();
  if (authorize && !hasAnyRoles(authorize) && authorize.length == 1 && authorize[0] == Role.guest) {
    return next({ name: "home" });
  }

  if (authorize && !hasAnyRoles(authorize)) {
    return next({ path: "/error-404" });
  }

  next();
});

export default router;
