import _ from "lodash";

import config from "@/config/alquant";

const routes = [];

const indicatorsRoutes = _.flattenDeep(
  config.indicators?.map((indicatorConfig) => {
    return [
      {
        path: `/indicators/${indicatorConfig.id}`,
        name: `indicators_${indicatorConfig.storeModule}`,
        component: () => import("../views/indicators/DefaultOverview.vue"),
        meta: {
          authorize: indicatorConfig.allowedRoles,
        },
        props: {
          indicatorId: indicatorConfig.id,
          storeModule: indicatorConfig.storeModule,
          indicatorName: indicatorConfig.name,
          indicatorSubtitle: indicatorConfig.subtitle,
          indicatorDescription: indicatorConfig.description,
          illustrationPath: indicatorConfig.illustrationPath,
          indicatorColor: indicatorConfig.indicatorColor,
          indicatorInfo: indicatorConfig.info,
          indicatorStats: indicatorConfig.stats,
          timelineData: indicatorConfig.timelineData,
          howWeUseIndicatorText: indicatorConfig.howWeUseIndicatorText,
          metaDescription: indicatorConfig.metaDescription,
          metaKeywords: indicatorConfig.metaKeywords,
        },
      },
      {
        path: `/indicators/${indicatorConfig.id}/use-case`,
        name: `indicators_${indicatorConfig.storeModule}_useCase`,
        component: () => import("../views/indicators/DefaultUseCase.vue"),
        meta: {
          authorize: indicatorConfig.allowedRoles,
        },
        props: {
          indicatorId: indicatorConfig.id,
          storeModule: indicatorConfig.storeModule,
          indicatorName: indicatorConfig.name,
          indicatorColor: indicatorConfig.indicatorColor,
          events: indicatorConfig.events,
          howWeUseIndicatorText: indicatorConfig.howWeUseIndicatorText,
        },
      },
      {
        path: `/indicators/${indicatorConfig.id}/notifications`,
        name: `indicators_${indicatorConfig.storeModule}_notifications`,
        component: () => import("../views/indicators/DefaultNotifications.vue"),
        meta: {
          authorize: indicatorConfig.allowedRoles,
        },
        props: {
          indicatorId: indicatorConfig.id,
          storeModule: indicatorConfig.storeModule,
          indicatorName: indicatorConfig.name,
        },
      },
      {
        path: `/indicators/${indicatorConfig.id}/documents`,
        name: `indicators_${indicatorConfig.storeModule}_documents`,
        component: () => import("../views/indicators/DefaultDocuments.vue"),
        meta: {
          authorize: indicatorConfig.allowedRoles,
        },
        props: {
          indicatorId: indicatorConfig.id,
          storeModule: indicatorConfig.storeModule,
          indicatorName: indicatorConfig.name,
        },
      },
    ];
  })
);

routes.push(...indicatorsRoutes);

export default routes;
