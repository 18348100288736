import firebase from "firebase/compat/app";

import store from "../store/store";
import { Role } from "./roles";

let currentUserRoles = new Set([Role.guest]);
let lastTokenIssuedDay = null;

const getUserRoleFromString = (stringRole) => {
  if (stringRole in Role) {
    return Role[stringRole];
  } else {
    return Role.guest;
  }
};

export const setAndGetUserRoles = () => {
  const user = firebase.auth().currentUser;
  if (!user) {
    currentUserRoles = new Set([Role.guest]);
    return currentUserRoles;
  }

  return user.getIdTokenResult().then(({ issuedAtTime, claims }) => {
    //
    const issuedOnDay = new Date(issuedAtTime).toISOString().split("T")[0];
    if (lastTokenIssuedDay == null || issuedOnDay != lastTokenIssuedDay) {
      store.dispatch("userInfo/updateUserInfo", {
        newUserInfo: {
          lastVisitedDay: issuedOnDay,
        },
      });
      lastTokenIssuedDay = issuedOnDay;
    }

    // Keep only role with a true value, and remove the "role:"" substring
    const firebaseRoles = Object.keys(claims)
      .filter((key) => key.startsWith("role:") && claims[key])
      .map((role) => role.substring("role:".length));
    const roles = firebaseRoles.map((stringRole) => getUserRoleFromString(stringRole));
    // Add user role to be able to detect between authenticated user and non-authenticated user.
    roles.push(Role.insight);
    currentUserRoles = new Set(roles);
    return new Set(roles);
  });
};

export const hasAnyRole = (role) => {
  return currentUserRoles.has(role);
};

export const hasAnyRoles = (roles) => {
  return new Set(roles.filter((role) => hasAnyRole(role))).size > 0;
};
