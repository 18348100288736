/*=========================================================================================
  File Name: main.js
  Description: main vue(js) file
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import "material-icons/iconfont/material-icons.css"; //Material Icons
import "vuesax/dist/vuesax.css"; // Vuesax

import Vue from "vue";
// Vuesax Component Framework
import Vuesax from "vuesax";

import App from "./App.vue";
Vue.use(Vuesax);

// axios
import axios from "./http/axios/index.js";
Vue.prototype.$http = axios;

// Theme Configurations
import "../themeConfig.js";
// Firebase
import "./firebase/firebaseConfig.js";
// Globally Registered Components
import "./globalComponents.js";
// Styles: SCSS
import "./assets/scss/main.scss";
// Tailwind
import "./assets/css/main.css";
// Vuexy Admin Filters
import "./filters/filters";

import firebase from "firebase/compat/app";
// VeeValidate
import { ValidationObserver, ValidationProvider } from "vee-validate";

// Vue Router
import router from "./router/index";
// Vuex Store
import store from "./store/store";
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
// Import validation rules
import "./validation/validation.js";

// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from "vue2-hammer";
Vue.use(VueHammer);

// Vue phone number input
import "vue-phone-number-input/dist/vue-phone-number-input.css";

import VuePhoneNumberInput from "vue-phone-number-input";
Vue.component("VuePhoneNumberInput", VuePhoneNumberInput);

// reCAPTCHA
import { VueReCaptcha } from "vue-recaptcha-v3";
Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY });

// Allow scroll to element on click + keep track of active element
import VueScrollactive from "vue-scrollactive";
Vue.use(VueScrollactive);

import VTooltip from "v-tooltip";
Vue.use(VTooltip, {
  defaultTrigger: "hover focus touch click",
  defaultClass: "text-xs md:text-base",
});

import VueMeta from "vue-meta";
Vue.use(VueMeta);

import VueScrollTo from "vue-scrollto";
Vue.use(VueScrollTo);

import * as Sentry from "@sentry/vue";
Sentry.init({
  Vue,
  dsn: "https://fa7b32f003e44b86a19af018ce59e952@o1283472.ingest.sentry.io/6511768",
  integrations: [
    Sentry.browserTracingIntegration({
      router,
    }),
  ],
  tracePropagationTargets: [process.env.VUE_APP_CLIENT_URL, process.env.VUE_APP_API_URL, /^\//],
  denyUrls: ["localhost"],
  release: process.env.VUE_APP_SENTRY_RELEASE,
  // Let's register all transactions for now, as we don't have a lot of users.
  tracesSampleRate: 1.0,
  logErrors: true,
  environment: process.env.NODE_ENV,
});

// Import Role function to be used in whole application
import { hasAnyRole, hasAnyRoles } from "./acl";
import {
  SERVER_ERROR_OTHER_EVENT,
  SERVER_ERROR_REQUEST_EVENT,
  SERVER_ERROR_RESPONSE_EVENT,
} from "./assets/constants/analytics";
Vue.mixin({
  methods: {
    hasAnyRole,
    hasAnyRoles,
  },
});

// Add d3 locale format everywhere. If we don't do this
// the dash is replaced by a minus sign, which is bigger,
// and we have always "worked" with the dash.
import { formatLocale } from "d3";
Vue.prototype.$d3FormatLocale = formatLocale({
  decimal: ".",
  thousands: ",",
  grouping: [3],
  minus: "\u002D",
}).format;

// Feather font icon
require("./assets/css/iconfont.css");

// Vue select css
// Note: In latest version you have to add it separately
// import 'vue-select/dist/vue-select.css';

Vue.config.productionTip = false;

let app;
firebase.auth().onAuthStateChanged((user) => {
  if (!app)
    app = new Vue({
      router,
      store,
      render: (h) => h(App),
    }).$mount("#app");
  if (user) {
    store.dispatch("auth/setLoginInfo", user);
  } else {
    store.dispatch("auth/signOut");
  }
});

axios.interceptors.request.use(
  async (config) => {
    if (
      config.headers &&
      (!config.headers["Authorization"] || config.headers["Authorization"] == "")
    ) {
      const token = await store.dispatch("auth/setToken");
      if (token) config.headers["Authorization"] = `FirebaseToken ${token}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(undefined, async (error) => {
  // Sometimes, it can happen that the FirebaseToken is not valid anymore
  // (e.g. if the user didn't refresh for the time of life of the token), so
  // in this case the backend could return a response with a 4xx status code.
  // If this is the case, we want to retry one single time with a new token.
  const statusCodesToRetry = [401, 403];
  const statusCodesToSignout = [498];
  const originalRequest = error.config;
  // We need to transform back the headers from the AxiosHeader object to
  // a simple javascript object, otherwise if we retry this AxiosHeader is
  // badly interpreted by axios itself and transformed in [Object object].
  // https://github.com/axios/axios/issues/5089
  if (originalRequest && originalRequest.headers) {
    originalRequest.headers = JSON.parse(JSON.stringify(originalRequest.headers));
  }

  if (error.response && statusCodesToRetry.includes(error.response.status)) {
    if (!originalRequest._retry) {
      originalRequest._retry = true;
      // Retry but make sure to set the new token first.
      const token = await store.dispatch("auth/setToken");
      originalRequest.headers["Authorization"] = `FirebaseToken ${token}`;
      return axios(originalRequest);
    }
  } else if (error.response && statusCodesToSignout.includes(error.response.status)) {
    // If the token was revoked, sign out the user and remove the authorization header
    await store.dispatch("auth/signOut");
    return router.push({
      name: "page-login",
      params: { tokenInvalidated: true },
    });
  } else if (error.code && error.code == "ERR_NETWORK") {
    // In the case where cloud run wasn't able to handle the query because we
    // took more than 10 seconds to accept the query, we will retry as a it's
    // likely because a machine cold start, and if we retry it's likely hot.
    // We check for network error, because it's what axios get in this case,
    // as the response is sent back by cloud run which doesn't set the CORS
    // flag as our backend does.
    if (originalRequest._nRetries) {
      originalRequest._nRetries += 1;
    } else {
      originalRequest._nRetries = 1;
    }

    // We are at our 12 tentatives (counting original one), it means
    // we have been blocked for 2 minutes in the case of a 429
    // error, we stop.
    if (originalRequest._nRetries == 12) {
      return Promise.reject(error);
    }

    firebase.analytics().logEvent(SERVER_ERROR_RESPONSE_EVENT, {
      response_status_code: 429,
      response_status_text: `Retry ${originalRequest._nRetries}`,
      server_endpoint: originalRequest.url,
    });

    return axios(originalRequest);
  }

  // If not an error that we solved, we log one in analytics.
  let errorRequestUrl = error.config.url;
  if (error.config.params && Object.keys(error.config.params).length > 0) {
    errorRequestUrl += `?${Object.entries(error.config.params)
      .map((x) => x[0] + "=" + x[1])
      .join("&")}`;
  }

  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx.
    firebase.analytics().logEvent(SERVER_ERROR_RESPONSE_EVENT, {
      response_status_code: error.response.status,
      response_status_text:
        error.response.statusText || error.message
          ? error.response.statusText + " | " + error.message
          : error,
      server_endpoint: errorRequestUrl,
    });
  } else if (error.request) {
    // The request was made but no response was received and
    // `error.request` is an instance of XMLHttpRequest in the browser.
    firebase.analytics().logEvent(SERVER_ERROR_REQUEST_EVENT, {
      response_status_code: error.code ?? error.name,
      response_status_text:
        error.code || error.name || error.message
          ? error.code + " | " + error.name + " | " + error.message
          : error,
      server_endpoint: errorRequestUrl,
    });
  } else {
    // Something happened in setting up the request that triggered an Error.
    firebase.analytics().logEvent(SERVER_ERROR_OTHER_EVENT, {
      response_status_text: error.message ?? error,
      server_endpoint: errorRequestUrl,
    });
  }

  return Promise.reject(error);
});

export { app };
