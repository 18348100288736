const routes = [
  {
    path: "/portfolios/new",
    name: "portfolios_new",
    component: () => import("../views/portfolios/New.vue"),
  },
  {
    path: "/portfolios/:portfolioId",
    name: "portfolios_simulation",
    component: () => import("../views/portfolios/Simulation.vue"),
  },
  {
    path: "/portfolios/:portfolioId/edit",
    name: "portfolios_edit",
    component: () => import("../views/portfolios/Edit.vue"),
  },
];

export default routes;
