/* File containing constants related to major events and their dates. */

export const EVENT_DOT_COM = {
  name: "Dot-com bubble",
  start: "2000-03-01",
  end: "2004-03-01",
};
export const EVENT_FINANCIAL_CRISIS = {
  name: "Financial crisis",
  start: "2008-01-17",
  end: "2010-01-01",
};
export const EVENT_EURO_CRISIS = {
  name: "Euro crisis",
  start: "2011-02-18",
  end: "2012-03-01",
};
export const EVENT_DECEMBER_2018_CRISIS = {
  name: "December 2018 crisis",
  start: "2018-10-01",
  end: "2019-04-01",
};
export const EVENT_COVID19_CRISIS = {
  name: "COVID-19 crisis",
  start: "2019-12-01",
  end: "2020-12-01",
};
