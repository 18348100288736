import { Role } from "@/acl/roles";
import { ALQUANT_INDICATORS_DETAILS } from "@/assets/constants/indicators/alquant";

export default {
  orgName: "Alquant",
  orgLegalName: "Alquant SA",
  logoPath: require("@/assets/images/logo/prisma.svg"),
  allowedLoginMethods: ["Google"],
  indicators: ALQUANT_INDICATORS_DETAILS,
  possibleRoles: [Role.superAdmin, Role.admin, Role.employee, Role.indicator],
  indicatorDisclaimerText:
    "Disclaimer: Alquant SA accepts no responsibility for any losses that may result from the use of these indicators, which are only provided for information purposes. Information on this website should not be interpreted as investment advice or an offer to sell, or the solicitation of offers to buy, any security. It is the responsibility of any person or persons in possession of this material to inform themselves of and to observe all applicable laws and regulations of any relevant jurisdiction. Past performance is not a guide to future performance.",
  ombudsmanText:
    "In case of dispute with Alquant SA, clients have the possibility to initiate mediation proceedings through Alquant's ombudsman FINOS by accessing the following link: https://www.finos.ch/mediation/?lang=en",
};
