/*=========================================================================================
  File Name: moduleAuthActions.js
  Description: Auth Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import "firebase/compat/auth";
import "firebase/compat/firestore";

import firebase from "firebase/compat/app";

export default {
  loginAttempt({ dispatch }, userDetails) {
    return new Promise((resolve, reject) => {
      // If remember_me is enabled change firebase Persistence
      if (!userDetails.checkboxRememberMe) {
        // Change firebase Persistence
        firebase
          .auth()
          .setPersistence(firebase.auth.Auth.Persistence.SESSION)
          .then(() => {
            dispatch("loginWithEmailAndPassword", userDetails)
              .then((userCredential) => {
                resolve(userCredential);
              })
              .catch((error) => {
                // Redirect reject from our own promise.
                reject(error);
              });
          })
          .catch((error) => {
            // We want a contact us button whatever the error on `setPersistence`
            reject({
              errorToDisplay: "An internal error occurred. Please contact us.",
              errorToLog: error.message || error,
              error,
              withRedirectButton: "contact-us",
            });
          });
      } else {
        // Try to login
        dispatch("loginWithEmailAndPassword", userDetails)
          .then((userCredential) => {
            resolve(userCredential);
          })
          .catch((error) => {
            // Redirect reject from our own promise.
            reject(error);
          });
      }
    });
  },
  loginWithEmailAndPassword({ getters, dispatch }, userDetails) {
    return new Promise((resolve, reject) => {
      // As users haven't access to auth pages once logged in,
      // they shouldn't be able to log again while being logged.
      if (getters.isAuthenticated) {
        reject({
          errorToLog: "Already authenticated.",
        });
        return;
      }

      // Try to signin
      firebase
        .auth()
        .signInWithEmailAndPassword(userDetails.email, userDetails.password)
        .then((userCredential) => {
          if (!userCredential.user.emailVerified) {
            // If the email isn't verified, we stopped the logging.
            dispatch("signOut").finally(() => {
              reject({
                errorToDisplay:
                  "You need to verify your email before trying to connect on our platform. Please check your emails (and your spam folder) to find the email. If you cannot find it, reset your password.",
                errorToLog: "Missing email verification",
                withRedirectButton: "reset-password",
              });
            });
          } else {
            // If the user doesn't have a Firestore user it means it tries to log
            // in without being registered first, hence we stopped the process and
            // ask to register first.
            dispatch("isUserInFirestore", userCredential.user.uid)
              .then((isUserInFirestore) => {
                if (isUserInFirestore) {
                  resolve(userCredential);
                } else {
                  userCredential.user.delete().finally(() => {
                    reject({
                      errorToDisplay:
                        "You need to register first before logging in. Please create an account.",
                      errorToLog:
                        "Try login without register with email and password (missing Firestore)",
                      withRedirectButton: "register",
                    });
                  });
                }
              })
              .catch((error) => {
                // Redirect reject from our own promise.
                reject(error);
              });
          }
        })
        .catch((error) => {
          // If the user isn't found or it's the wrong password,
          // we don't want people to contact us.
          if (error.code == "auth/user-not-found") {
            reject({
              errorToDisplay:
                "You need to register first before logging in. Please create an account.",
              errorToLog: "Try login without register with email and password",
              withRedirectButton: "register",
            });
          } else if (error.code == "auth/wrong-password") {
            reject({
              errorToDisplay:
                "Wrong password. If you don't remember your password, you can reset it.",
              errorToLog: "Wrong password",
              withRedirectButton: "reset-password",
            });
          } else {
            reject({
              errorToDisplay: "An internal error occurred. Please contact us.",
              errorToLog: error.message || error,
              error,
              withRedirectButton: "contact-us",
            });
          }
        });
    });
  },
  // Google Login
  loginWithGoogle({ getters, dispatch }) {
    return new Promise((resolve, reject) => {
      // As users haven't access to auth pages once logged in,
      // they shouldn't be able to log again while being logged.
      if (getters.isAuthenticated) {
        reject({
          errorToLog: "Already authenticated.",
        });
        return;
      }

      const provider = new firebase.auth.GoogleAuthProvider();
      provider.setCustomParameters({
        prompt: "select_account",
      });
      firebase
        .auth()
        .signInWithPopup(provider)
        .then((userCredential) => {
          // At this stage, if we have more than 1 provider, it means we are
          // already logged in through password. We don't want to authorize
          // this, hence we unlink the Google provider and return an error message.

          // Note: if the user hasn't verified its email after registering with
          // a password, then Google provider will *overwrite* the password
          // provider. It is an expected behavior and complex to check.
          // Here we will assume that if the user wanted to log with password, and
          // not Google while available during registration, it was somehow a misclick.
          // https://stackoverflow.com/questions/37396194/firebase-logging-in-with-new-provider-google-removes-previous-provider-passwo
          if (userCredential.user.providerData.length > 1) {
            // Note that we don't need a catch here because the only error
            // that can arise from unlink is if the providerId is wrong,
            // and we defined it from the official provider just above.
            userCredential.user.unlink(provider.providerId).then(() => {
              // Bad case, the user is already registered with this email with a
              // password.
              dispatch("signOut").finally(() => {
                reject({
                  errorToDisplay:
                    "You are already registered with this email with a password. Please login with this email and your password.",
                  errorToLog: "Try login with Google while having register with email and password",
                  withRedirectButton: false,
                });
              });
            });
          } else {
            // If the user doesn't have a Firestore user it means it tries to log
            // in without being registered first, hence we stopped the process and
            // ask to register first.
            dispatch("isUserInFirestore", userCredential.user.uid)
              .then((isUserInFirestore) => {
                if (isUserInFirestore) {
                  resolve(userCredential);
                } else {
                  userCredential.user.delete().finally(() => {
                    reject({
                      errorToDisplay:
                        "You need to register first before logging in. Please create an account.",
                      errorToLog:
                        "Try login with Google without register with Google (missing Firestore)",
                      withRedirectButton: "register",
                    });
                  });
                }
              })
              .catch((error) => {
                // Redirect reject from our own promise.
                reject(error);
              });
          }
        })
        .catch((error) => {
          // The only error that doesn't require a contact us button
          // is if the user close the popup instead of logging through it.
          if (error.code == "auth/popup-closed-by-user") {
            reject({
              errorToLog: "Google popup closed",
            });
          } else {
            reject({
              errorToDisplay: "An internal error occurred. Please contact us.",
              errorToLog: error.message || error,
              error,
              withRedirectButton: "contact-us",
            });
          }
        });
    });
  },
  registerWithGoogle({ dispatch, getters }, userDetails) {
    return new Promise((resolve, reject) => {
      // As users haven't access to auth pages once logged in,
      // they shouldn't be able to log again while being logged.
      if (getters.isAuthenticated) {
        reject({
          errorToLog: "Already authenticated.",
        });
        return;
      }

      const provider = new firebase.auth.GoogleAuthProvider();
      firebase
        .auth()
        .signInWithPopup(provider)
        .then((userCredential) => {
          // At this stage, if we have more than 1 provider, it means we are
          // already logged in through password. We don't want to authorize
          // this, hence we unlink the Google provider and return an error message.
          if (userCredential.user.providerData.length > 1) {
            // Note that we don't need a catch here because the only error
            // that can arise from unlink is if the providerId is wrong,
            // and we defined it from the official provider just above.
            userCredential.user.unlink(provider.providerId).then(() => {
              // Bad case, the user is already registered with this email with a
              // password.
              dispatch("signOut").finally(() => {
                reject({
                  errorToDisplay:
                    "You are already registered with this email with a password. Please login with this email and your password.",
                  errorToLog:
                    "Try register with Google while having register with email and password",
                  withRedirectButton: "login",
                });
              });
            });
          } else {
            dispatch("isUserInFirestore", userCredential.user.uid)
              .then((isUserInFirestore) => {
                if (isUserInFirestore) {
                  // Bad case, the user is already registered with this email.
                  dispatch("signOut").finally(() => {
                    reject({
                      errorToDisplay:
                        "You are already registered with this Google account. Please login with this account.",
                      errorToLog:
                        "Try register with Google while having register with Google already",
                      withRedirectButton: "login",
                    });
                  });
                } else {
                  // Good case where the user tries to register for the first time
                  // (or at least doesn't have a Firestore user yet).
                  const userInfoToSave = {
                    firstName: userDetails.firstName,
                    lastName: userDetails.lastName,
                    email: userCredential.user.email,
                    country: userDetails.country,
                    investorProfile: userDetails.investorProfile,
                    company: userDetails.company,
                    telNumber: userDetails.telNumber ?? "",
                    telNumberCountryCode: userDetails.telNumber
                      ? userDetails.telNumberCountryCode
                      : "",
                  };
                  // Create special document for this user in firestore.
                  dispatch(
                    "userInfo/createUserInfo",
                    {
                      uid: userCredential.user.uid,
                      userInfo: userInfoToSave,
                    },
                    { root: true }
                  )
                    .then(() => {
                      // Final good case, we have been able to save the data Firestore.
                      resolve(userCredential);
                    })
                    .catch((error) => {
                      // If we get an error while trying to save in Firestore, we stop
                      // and kill the registration, hence delete the newly created user
                      // in Firebase.
                      userCredential.user.delete().finally(() => {
                        reject({
                          errorToDisplay: "An internal error occurred. Please contact us.",
                          errorToLog:
                            "Couldn't create in userInfo in Firestore. " + (error.message || error),
                          error,
                          withRedirectButton: "contact-us",
                        });
                      });
                    });
                }
              })
              .catch((error) => {
                // Redirect reject from our own promise.
                reject(error);
              });
          }
        })
        .catch((error) => {
          if (firebase.auth().currentUser) {
            // If at this point we are still somewhat logged in, while still getting
            // an error, it means we have a partial user creation, that should lead
            // to a cleaning of the partially created account.
            firebase
              .auth()
              .currentUser.delete()
              .finally(() => {
                reject({
                  errorToDisplay: "An internal error occurred. Please contact us.",
                  errorToLog: "Partial user creation. " + (error.message || error),
                  error,
                  withRedirectButton: "contact-us",
                });
              });
          } else {
            // The only error that doesn't require a contact us button
            // is if the user close the popup instead of logging through it.
            if (error.code == "auth/popup-closed-by-user") {
              reject({
                errorToLog: "Google popup closed",
              });
            } else {
              reject({
                errorToDisplay: "An internal error occurred. Please contact us.",
                errorToLog: error.message || error,
                error,
                withRedirectButton: "contact-us",
              });
            }
          }
        });
    });
  },
  registerWithEmailAndPassword({ getters, dispatch }, userDetails) {
    return new Promise((resolve, reject) => {
      // As users haven't access to auth pages once logged in,
      // they shouldn't be able to log again while being logged.
      if (getters.isAuthenticated) {
        reject({
          errorToLog: "Already authenticated.",
        });
        return;
      }

      // create user using firebase
      firebase
        .auth()
        .createUserWithEmailAndPassword(userDetails.email, userDetails.password)
        .then((userCredential) => {
          userCredential.user
            .sendEmailVerification()
            .then(() => {
              // Update profile in firebase auth.
              dispatch("updateProfile", {
                user: userCredential.user,
                displayName: `${userDetails.firstName} ${userDetails.lastName}`,
                photoURL: userDetails.photoURL,
              })
                .then(() => {
                  const userInfoToSave = {
                    firstName: userDetails.firstName,
                    lastName: userDetails.lastName,
                    email: userDetails.email,
                    country: userDetails.country,
                    investorProfile: userDetails.investorProfile,
                    company: userDetails.company,
                    telNumber: userDetails.telNumber ?? "",
                    telNumberCountryCode: userDetails.telNumber
                      ? userDetails.telNumberCountryCode
                      : "",
                  };
                  // Create special document for this user in firestore.
                  dispatch(
                    "userInfo/createUserInfo",
                    {
                      uid: userCredential.user.uid,
                      userInfo: userInfoToSave,
                    },
                    { root: true }
                  )
                    .then(() => {
                      dispatch("signOut").then(() => {
                        resolve(userCredential);
                      });
                    })
                    .catch((error) => {
                      // If we get an error while trying to save in Firestore, we stop
                      // and kill the registration, hence delete the newly created user
                      // in Firebase.
                      userCredential.user.delete().finally(() => {
                        reject({
                          errorToDisplay: "An internal error occurred. Please contact us.",
                          errorToLog:
                            "Couldn't create in userInfo in Firestore. " + (error.message || error),
                          error,
                          withRedirectButton: "contact-us",
                        });
                      });
                    });
                })
                .catch((error) => {
                  // We couldn't update the profile for some reason.
                  userCredential.user.delete().finally(() => {
                    reject(error);
                  });
                });
            })
            .catch((error) => {
              // Whatever the error, we want a contact us button.
              userCredential.user.delete().finally(() => {
                reject({
                  errorToDisplay: "An internal error occurred. Please contact us.",
                  errorToLog: error.message || error,
                  error,
                  withRedirectButton: "contact-us",
                });
              });
            });
        })
        .catch((error) => {
          if (firebase.auth().currentUser) {
            // If at this point we are still somewhat logged in, while still getting
            // an error, it means we have a partial user creation, that should lead
            // to a cleaning of the partially created account.
            firebase
              .auth()
              .currentUser.delete()
              .finally(() => {
                reject({
                  errorToDisplay: "An internal error occurred. Please contact us.",
                  errorToLog: "Partial user creation. " + (error.message || error),
                  error,
                  withRedirectButton: "contact-us",
                });
              });
          } else {
            // We don't want a contact us button if the user tries to register
            // with an email already used or if the password is too weak.
            if (error.code == "auth/email-already-in-use") {
              reject({
                errorToDisplay:
                  "You already have an account with this email. Please login to your account.",
                errorToLog:
                  "Try register with email and password while having register with this email",
                withRedirectButton: "login",
              });
            } else if (error.code == "auth/weak-password") {
              reject({
                errorToDisplay: error.message || error,
                errorToLog: "Weak password",
                withRedirectButton: false,
              });
            } else {
              reject({
                errorToDisplay: "An internal error occurred. Please contact us.",
                errorToLog: error.message || error,
                error,
                withRedirectButton: "contact-us",
              });
            }
          }
        });
    });
  },

  setUser({ commit }, user) {
    if (!user) commit("SET_USER", null);
    const userToSave = {
      displayName: user.displayName,
      email: user.email,
      photoURL: user.photoURL ? user.photoURL : require("@/assets/images/profile/user.svg"),
      uid: user.uid,
    };
    commit("SET_USER", userToSave);
  },

  signOut({ commit }) {
    return new Promise((resolve) => {
      firebase
        .auth()
        .signOut()
        .then(() => {
          commit("SET_USER", null);
          resolve();
        });
    });
  },

  updateProfile(_, userDetails) {
    return new Promise((resolve, reject) => {
      const newProfile = {};
      if (userDetails.displayName) newProfile.displayName = userDetails.displayName;
      if (userDetails.photoURL) newProfile.photoURL = userDetails.photoURL;
      userDetails.user
        .updateProfile(newProfile)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          // From doc, no possible error normally apart from connection error,
          // so if something happens, we want a contact us button.
          reject({
            errorToDisplay: "An internal error occurred. Please contact us.",
            errorToLog: "Profile couldn't update. " + (error.message || error),
            error,
            withRedirectButton: "contact-us",
          });
        });
    });
  },

  // Returns whether the user has indeed their information stored in Firestore or not.
  isUserInFirestore(_, uid) {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection("users")
        .doc(uid)
        .get()
        .then((doc) => {
          resolve(doc.exists);
        })
        .catch((error) => {
          // From doc, no possible error normally apart from connection error,
          // so if something happens, we want a contact us button.
          reject({
            errorToDisplay: "An internal error occurred. Please contact us.",
            errorToLog: "Couldn't get information from Firestore. " + (error.message || error),
            error,
            withRedirectButton: "contact-us",
          });
        });
    });
  },

  setToken() {
    return new Promise((resolve) => {
      const user = firebase.auth().currentUser;
      if (!user) {
        resolve(null);
      } else {
        user.getIdToken().then((idToken) => {
          resolve(idToken);
        });
      }
    });
  },

  /**
   * Set the user info after the login, with the token and the roles
   */
  setLoginInfo({ dispatch }, user) {
    dispatch("setUser", user);
  },
  deleteAccount(_, { notify }) {
    const userEmail = firebase.auth().currentUser.email;
    return firebase
      .auth()
      .currentUser.delete()
      .then(() => {
        notify({
          time: 4000,
          title: "Success",
          text: "Your account was successfully deleted.",
          iconPack: "feather",
          icon: "icon-check",
          color: "success",
        });
        return userEmail;
      });
  },
  resetPasswordForGuest(_, { email }) {
    return firebase.auth().sendPasswordResetEmail(email);
  },
  updatePassword(_, { currentPassword, newPassword, notify }) {
    const user = firebase.auth().currentUser;
    const credential = firebase.auth.EmailAuthProvider.credential(user.email, currentPassword);
    // We need to reauthenticate the user for this kind of operations.
    user
      .reauthenticateWithCredential(credential)
      .then(() => {
        user
          .updatePassword(newPassword)
          .then(() => {
            notify({
              title: "Success",
              text: "Your password was successfully updated.",
              color: "success",
              time: 5000,
            });
          })
          .catch((error) => {
            notify({
              title: "Error",
              text: "We couldn't change your password, please contact us using the contact form to fix this error!",
              color: "danger",
            });

            // We rethrow the error for sentry to catch it.
            throw error;
          });
      })
      .catch(() => {
        notify({
          title: "Error",
          text: "You provided the wrong password.",
          color: "danger",
        });
      });
  },
};
