const routes = [
  {
    path: "/custom-indicators/new",
    name: "customIndicators_new",
    component: () => import("../views/customIndicators/New.vue"),
  },
  {
    path: "/custom-indicators/:customIndicatorId",
    name: "customIndicators_useCase",
    component: () => import("../views/customIndicators/UseCase.vue"),
  },
  {
    path: `/custom-indicators/:customIndicatorId/notifications`,
    name: `customIndicators_notifications`,
    component: () => import("../views/customIndicators/Notifications.vue"),
  },
  {
    path: "/custom-indicators/:customIndicatorId/edit",
    name: "customIndicators_edit",
    component: () => import("../views/customIndicators/Edit.vue"),
  },
];

export default routes;
