// Events name
export const SERVER_ERROR_EVENT = "server_error";
export const SERVER_ERROR_RESPONSE_EVENT = "server_error_response";
export const SERVER_ERROR_REQUEST_EVENT = "server_error_request";
export const SERVER_ERROR_OTHER_EVENT = "server_error_other";
export const CONTACT_EVENT = "contact_request";
export const ACCOUNT_REGISTRATION_EVENT = "new_registration";
export const LOGIN_EVENT = "new_login";
export const ACCOUNT_DELETION_EVENT = "delete_account";
export const EDIT_ACCOUNT_EVENT = "edit_account";
export const PASSWORD_RESET_EVENT = "password_reset";
export const UPDATE_DATE_EVENT = "update_date";
export const NOTIFICATIONS_UPDATE_EVENT = "notifications_update";
export const STATISTIC_SELECTION_EVENT = "statistic_selection";
export const BENCHMARK_SELECTION_EVENT = "benchmark_selection";
export const UNDERLYING_SELECTION_EVENT = "underlying_selection";
export const TOOLTIP_DISPLAYED_EVENT = "tooltip_hover";
export const USER_ROLE_CHANGE_EVENT = "user_role_change";
export const MANUAL_EMAIL_VERIFICATION = "manual_email_verification";
export const COLLAPSE_CLICKED_EVENT = "collapse_clicked";
export const BUTTON_CLICKED_EVENT = "button_clicked";
export const TEXT_CLICKED_EVENT = "text_clicked";
export const CREATE_CUSTOM_INDICATOR_EVENT = "create_custom_indicator";
export const EDIT_CUSTOM_INDICATOR_EVENT = "edit_custom_indicator";
export const DELETE_CUSTOM_INDICATOR_EVENT = "delete_custom_indicator";
export const DOWNLOAD_DATA_EVENT = "download_data";
export const DOWNLOAD_DOCUMENT_EVENT = "download_document";
export const CREATE_PORTFOLIO_EVENT = "create_portfolio";
export const EDIT_PORTFOLIO_EVENT = "edit_portfolio";
export const DELETE_PORTFOLIO_EVENT = "delete_portfolio";
export const BACKTEST_PORTFOLIO_EVENT = "backtest_portfolio";
export const EXTERNAL_URL_CLICKED = "external_url_clicked";
export const REBALANCING_FREQUENCY_SELECTION_EVENT = "rebalancing_frequency_selection";
export const USE_CASE_PARAMETERS_CHANGE_EVENT = "use_case_parameters_change";
export const SECURITY_SEARCH_EVENT = "security_search";
export const TAA_LAUNCH_EVENT = "taa_launch";
export const NEW_TOUR_START_EVENT = "new_tour_start";
export const NEW_TOUR_STOP_EVENT = "new_tour_stop";
export const NEW_TOUR_STEP_EVENT = "new_tour_step";
export const ERROR404_EVENT = "error404";
export const MISSING_PRICES_EVENT = "missing_prices";

// Fixed variable values
export const STATUS_SUCCESS = "success";
export const STATUS_WARNING = "warning";
export const STATUS_FAILURE = "failure";
export const GOOGLE = "Google";
export const MICROSOFT = "Microsoft";
export const EMAIL_AND_PWD = "Email and password";
